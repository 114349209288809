
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPageSuccess from './LandingPageSuccess';
import LandingPageFailed from './LandingPageFailed';
import LandingPageHeaderFlow from './LandingPageHeaderFlow';
import LandingPagePinFlow from './LandingPagePinFlow';
import Page7564 from './gl/7564/Page7564';
import Page1711 from './gl/1711/Page1711';

function App({ti, ts, camp, params}) 
{
  return (
    <Router>
      <Routes>
        <Route path="/lp/he" element={camp === 2001 ? (<Page1711 ti={ti} ts={ts}/>) :
            (camp === 2002 ? (<Page7564 ti={ti} ts={ts}/>) : (<LandingPageHeaderFlow ti={ti} ts={ts} />))} />
        <Route path="/lp/pin" element={<LandingPagePinFlow />} />
        <Route path="/lp/success" element={<LandingPageSuccess />} />
        <Route path="/lp/failed" element={<LandingPageFailed />} />
      </Routes>
    </Router>
  );
}

export default App;