import { format } from 'date-fns';

class ApiSender {
  constructor() {
  }

  async sendPin(endpoint, msisdn) {
    try {
      const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
      const response = await fetch(baseUrl + endpoint + msisdn, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept-Encoding' : 'gzip'
        }
      });

      if (response.ok) {
        const result = await response.json();
        if (result.code === 2000) {
          return { success: true, message: result.message };
        }
        else {
          return { success: false, message: result.message };
        }
      }
      else {
        return { success: false, message: "فشل إرسال رقم التعريف الشخصي" };
      }
    }
    catch (error) {
      return { success: false, message: "فشل إرسال رقم التعريف الشخصي" };
    }
  }

  async verifyPin(endpoint, msisdn, otp, campaignId, trackerId, gclid, gbraid, wbraid, conversionGoalId, conversionGoalName,
    landingPageUrl, landingPageName) {
    try {
      const date = new Date();
      const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
      const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
      const response = await fetch(baseUrl + endpoint, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept-Encoding' : 'gzip'
        },
        body: JSON.stringify({
          msisdn: msisdn,
          pin: otp,
          campaignId: campaignId,
          trackerId: trackerId,
          googleTrackerId: gclid,
          gbraid: gbraid,
          wbraid: wbraid,
          conversionDateTime: formattedDateTime,
          conversionGoalId: conversionGoalId,
          conversionGoalName: conversionGoalName,
          landingPageUrl: landingPageUrl,
          landingPageName: landingPageName
        })
      });

      if (response.ok) {
        const result = await response.json();
        if (result.code === 2000) {
          return { success: true, message: result.message };
        }
        else {
          return { success: false, message: result.message };
        }
      }
      else {
        return { success: false, message: "فشل التحقق من رقم التعريف الشخصي" };
      }
    }
    catch (error) {
      return { success: false, message: "فشل التحقق من رقم التعريف الشخصي" };
    }
  }

  async saveHistoryHe(ti, ts, params, gbraid, wbraid, conversionGoalId, conversionGoalName, landingPageUrl, landingPageName) {
    try {
      const date = new Date();
      const formattedDateTime = format(date, 'yyyy-MM-dd HH:mm:ssxxx');
      const baseUrl = process.env.REACT_APP_IRAQ_BASE_URL;
      const response = await fetch(baseUrl + 'game/subscribe',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            'Accept-Encoding' : 'gzip'
          },
          body: JSON.stringify({
            transactionId: ti,
            campaignId: params.campaignId,
            trackerId: params.trackerId,
            googleTrackerId: params.gclid,
            mfId: params.mfid,
            mfToken: params.mf_token,
            gbraid: gbraid,
            wbraid: wbraid,
            conversionDateTime: formattedDateTime,
            conversionGoalId: conversionGoalId,
            conversionGoalName: conversionGoalName,
            landingPageUrl: landingPageUrl,
            landingPageName: landingPageName,
            p1: params.p1,
            p2: params.p2,
            p3: params.p3,
            p4: params.p4,
            p5: params.p5,
            p6: params.p6,
            p7: params.p7,
            p8: params.p8,
            p9: params.p9,
            p10: params.p10
          })
        });
      if (response.ok) {
        const he_api_base = 'http://www.social-sms.com/iq-dcb/HE/v1.3/oneclick/sub.php';
        const serviceId = 721;
        const spId = 203;
        const shortcode = 3368;
        const servicename = 'gameland';
        const merchantname = 'strongbits';
        const redirectionUrl = `${he_api_base}?serviceId=${serviceId}&spId=${spId}&shortcode=${shortcode}&ti=${ti}&ts=${ts}&servicename=${servicename}&merchantname=${merchantname}`;
        window.location.href = redirectionUrl;
      }
    }
    catch (error) {
    }

  }

  async saveHistory(landingPageUrl, params) {
    try {
      let baseUrl = null;
      let endpoint = null;
      let extraParams = null;
      const isGoogleCampaign = params?.hasOwnProperty("gclid");
      if (isGoogleCampaign) {
        baseUrl = process.env.REACT_APP_GOOGLE_BASE_URL;
        endpoint = "google/visit";
        const list = ["gclid", "gbraid", "wbraid", "campaignId", "conversions", "cost_micros", "cpa", "utm_source", "utm_medium", "utm_campaign",
          "utm_term", "utm_content", "ad_group_id", "ad_group_name", "ad_id", "keyword_id", "keyword_text", "match_type", "clicks", "impressions",
          "ctr", "average_cpc", "conversion_date", "click_date", "geo_location", "device"];
        extraParams = Object.keys(params).reduce((acc, key) => {
          if (!list.includes(key)) {
            acc[key] = params[key];
          }
          return acc;
        }, {});
      }

      if (endpoint === null) {
        return;
      }

      await fetch(baseUrl + endpoint, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept-Encoding' : 'gzip'
        },
        body: JSON.stringify({
          gclid: params.gclid,
          gbraid: params.gbraid,
          wbraid: params.wbraid,
          campaign: params.campaignId,
          conversions: params.conversions,
          costMicros: params.cost_micros,
          cpa: params.cpa,
          utmSource: params.utm_source,
          utmMedium: params.utm_medium,
          utmCampaign: params.utm_campaign,
          utmTerm: params.utm_term,
          utmContent: params.utm_content,
          adGroupId: params.ad_group_id,
          adGroupName: params.ad_group_name,
          adId: params.ad_id,
          keywordId: params.keyword_id,
          keywordText: params.keyword_text,
          matchType: params.match_type,
          clicks: params.clicks,
          impressions: params.impressions,
          ctr: params.ctr,
          averageCpc: params.average_cpc,
          conversionDate: params.conversion_date,
          clickDate: params.click_date,
          geoLocation: params.geo_location,
          device: params.device,
          landingPageUrl: landingPageUrl,
          extraParams: JSON.stringify(extraParams)
        })
      });
    }
    catch (error) {
    }
  }
}

export default ApiSender;  