import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Page7564Style.css';
import ApiSender from '../../common/ApiLogic';

function Page7564({ ti, ts }) {

  const [goalId, setGoalId] = useState(null);
  const [goalName, setGoalName] = useState(null);
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = new URLSearchParams(location.search);
  const paramsObject = {};
  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  useEffect(() => {
    if (queryParams.has("campaignId")) {
      const fetchData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_GOOGLE_BASE_URL + 'campaign/info?uuid=' + queryParams.get("campaignId"),
            {
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                'Accept-Encoding' : 'gzip'
              }
            });
          if (response.ok) {
            const data = await response.json();
            const status = data.status;
            if (status) {
              setGoalId(data.goalId);
              setGoalName(data.goalName);
            }
          }
        }
        catch (error) {
        }
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    saveHistory();
  }, []);

  const saveHistory = async () => {
    const apiSender = new ApiSender();
    await apiSender.saveHistory(window.location.origin + location.pathname, paramsObject);
  }

  const handleSubscribe = async () => {
    document.getElementById("subscribe").disabled = true;
    const gbraid = queryParams.get("gbraid") ? queryParams.get("gbraid") : null;
    const wbraid = queryParams.get("wbraid") ? queryParams.get("wbraid") : null;
    const apiSender = new ApiSender();
    await apiSender.saveHistoryHe(ti, ts, paramsObject, gbraid, wbraid, goalId, goalName, window.location.origin + location.pathname, "LP 7564");
  }

  return (
    <div className="Aa7564">
      <div className='container'>
        <div className='form-content'>
          <div className='form-content-top'>
          </div>
          <div className='form-content-middle'>
            <div className='logo'><img alt='' loading='lazy' src='%PUBLIC_URL%/../../download-icon.png' /></div>
            <div className='title'>
              <h4 id='title'>جرب الآن</h4>
            </div>
            <div className='button'>
              <button id='subscribe' style={{ display: 'block' }} onClick={handleSubscribe}>حمل الآن <span class="arrow">&raquo;</span></button>
            </div>
          </div>
          <div className='form-content-bottom' style={{ direction: "rtl" }}>
            <span>خدمة Gameland قم بتنزيل ولعب أكثر من 100 لعبة مصممة لجميع الأعمار. تتيح لك هذه الخدمة الوصول إلى مجموعة متنوعة من الألعاب الممتعة والمناسبة لكل أفراد الأسرة. للاستمتاع بهذه الخدمة، يمكنك البدء بالتنزيل فوراً. في حال رغبتك في إلغاء، يرجى إرسال الكود UGL إلى الرقم 3368</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page7564;